<template>
    <div id="installation-step-five" class="div-height">
        <v-card height="100%">
            <v-card-title>
                <span class="ml-1">{{ $t('step_5.title') }} </span>
            </v-card-title>
            <v-card-text>
                <template>
                    <v-form ref="form">
                        <p>
                            <strong>{{ $t('asset_specification.equipment_information') }}</strong>
                        </p>
                        <p>
                            {{ $t('step_5.device_side') }} : <strong> {{ name_device_referentiel }}</strong>
                        </p>

                        <v-text-field v-if="address" v-model="address" :label="$t('address')" class="rounded-0" outlined />

                        <v-text-field
                            v-if="device_number"
                            v-model="device_number"
                            :label="$t('step_5.id_serial_number')"
                            class="rounded-0"
                            outlined
                        />

                        <v-text-field v-if="name_liquid" v-model="name_liquid" :label="$t('step_5.product')" class="rounded-0" outlined />

                        <v-text-field v-if="capacity" v-model="capacity" :label="$t('step_5.Capacity')" class="rounded-0" outlined />

                        <v-text-field
                            v-if="name_commercial_asset"
                            v-model="name_commercial_asset"
                            :label="$t('step_5.tank_format')"
                            class="rounded-0"
                            outlined
                        />

                        <v-row>
                            <v-col>
                                <v-btn block class="white--text" color="grey" large @click="skipStep()">
                                    {{ $t('step_5.skip') }}
                                </v-btn>
                            </v-col>

                            <v-col>
                                <v-btn block color="primary" large @click="externalInstall()">
                                    {{ $t('validate') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </template>
            </v-card-text>
        </v-card>
        <v-dialog v-model="isErrorInstall">
            <v-card>
                <v-card-title>
                    {{ $t('error_install') }}
                </v-card-title>

                <v-card-text>
                    {{ this.messageError }}

                    <v-btn block large text @click="isErrorInstall = false">
                        {{ $t('global.cancel') }}
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'InstallationStepFiveComponent',
    components: {},
    data() {
        return {
            address: '',
            name_liquid: '',
            messageError: '',
            isErrorInstall: false,
            configuration: null,
            name_commercial_asset: '',
        }
    },
    props: {
        name_device_referentiel: {
            type: String,
            required: true,
        },
        id_device_referentiel: {
            type: Number,
            required: false,
        },
        capacity: {
            type: Number,
            required: false,
        },
        device_number: {
            type: String,
            required: false,
        },
        id_device: {
            type: Number,
            required: false,
        },
        id_liquid: {
            type: Number,
            required: false,
        },
        asset_type: {
            type: Object,
            required: false,
        },
        asset: {
            type: Object,
            required: false,
        },
    },
    methods: {
        /**
         * skip external install
         */
        skipStep() {
            this.$emit('skipStep')
        },

        /**
         * add different external device installer
         */
        externalInstall() {
            if (this.id_device_referentiel === 244) {
                this.otoDataInstall()
            }
        },

        /**
         * install capteur on nee-vo app
         */
        otoDataInstall() {
            const listOtodataProduct = [
                'Propane',
                'LPG',
                'Diesel',
                'Clear Diesel',
                'Clear Diesel Efficient',
                'Clear Diesel Premium',
                'Dyed Diesel',
                'Dyed Diesel Efficient',
                'Dyed Diesel Premium',
                'Gasoline',
                'Regular Gasoline',
                'Super Gasoline',
                'Jet Fuel',
                'Kerosene',
                'Def',
                'Heating Oil',
                'Lubricant',
                'Butane',
                'Water',
                'Fertilizer',
            ]
            if (!listOtodataProduct.includes(this.name_liquid)) {
                this.isErrorInstall = true
                this.messageError = 'Curent product : ' + this.name_liquid + '\nValid product : ' + listOtodataProduct
            } else {
                this.configuration = this.asset.asset_models[0].configuration ?? {}
                const body = {
                    serialNumberDevice: this.device_number,
                    payload: {
                        is_installed: true,
                        address: this.address,
                        product: this.name_liquid,
                        capacity: this.capacity,
                        name_commercial_asset: this.name_commercial_asset,
                        configuration: this.configuration,
                    },
                }
                return this.axios
                    .post('api/v1/installation/' + this.id_device + '/OtoDataInstall', body)
                    .then(() => {
                        this.$emit('goToResult')
                    })
                    .catch((error) => {
                        this.manageError('error', this.t('step_4.error_during_installation'), error)
                    })
            }
        },
    },
}
</script>
